import Head from 'next/head'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { fetchUser } from '../components/auth/store/actions'
import { useDispatch } from 'react-redux'
import apiClient from '@mpe/api-client'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (loading) {
      return
    }

    setError(null)
    setLoading(true)

    const token = await apiClient.login(email, password)

    if (!token) {
      setError('Hibás adatok, a bejelentkezés sikertelen.')
      setLoading(false)

      return
    }

    localStorage.setItem('token', token)
    apiClient.setJwtToken(token)

    fetchUser()(dispatch)

    router.push('/')
  }

  return (
    <>
      <Head>
        <title>Belépés - MPE</title>
      </Head>

      <div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="card mt-3 mx-auto" style={{ maxWidth: 400 }}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="email">E-mail cím</label>

                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="E-mail cím"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Jelszó</label>

                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Jelszó"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {error && <div className="alert alert-danger">{error}</div>}

              <button type="submit" className={`btn btn-primary${!loading ? ' px-4' : ''}`} disabled={loading}>
                Belépés
                {loading && <div className="spinner-border spinner-border-sm ml-2 text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Login
